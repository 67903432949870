@tailwind base;
@tailwind components;
@tailwind utilities;

.next-error-h1,
.next-error-h1 + div h2{
  @apply dark:text-white
}

html {
  scroll-padding-top: calc(100px + var(--notification-banner-height))
}

.grecaptcha-badge {
  bottom: 80px !important;
}


/* --- Search Modal Styling --- */
em {
  @apply text-icon-accent-violet-strong font-circular-bold not-italic
}

.modal-container:has(.modal-top) {
 @apply !items-start
}

.modal-container:has(.modal-top) > .modal-content {
 @apply !mt-[80px]
}
/* --- */

/* --- Navigation Styling --- */
.cb-sidenav .cb-sidenav-item .inner {
    @apply !items-start
}

.cb-sidenav .cb-sidenav-group-item:hover {
    @apply !bg-none
}

.cb-sidenav .cb-sidenav-group-item:hover > span {
    @apply !bg-transparent
}
/* --- */


/* --- Input Styling --- */

.cb-input.disabled label {
  @apply !opacity-100
}

.cb-input.disabled label button {
  @apply !pointer-events-auto
}

/* --- */
